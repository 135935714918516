import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Sonoforeza Olsztyn | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/oferta/sonoforeza/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Piercing = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_4)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Sonoforeza</h1>

        <p>Jest to zabieg, w&nbsp;którym wykorzystuje się fale ultradźwiękowe. Wytwarzają one lekkie ciepło i&nbsp;wprowadzają w&nbsp;głąb skóry odpowiednio dobrany preparat. Może to być kwas hialuronowy, peptydy lub witaminy.</p>

        <p>Zabieg ten poprawia przepływ krwi i&nbsp;limfy. Wspomaga regenerację tkanek, mobiluzuje produkcję kolagenu i&nbsp;elastyny.</p>

        <h2>Przeciwwskazania</h2>

        <ul>
          <li>ciąża</li>
          <li>nowotwory</li>
          <li>gorączka</li>
          <li>epilepsja</li>
        </ul>

        <h2>Wskazania</h2>

        <ul>
          <li>odmładzanie</li>
          <li>kruche naczynia</li>
          <li>dotlenienie i&nbsp;nawilżanie skóry</li>
        </ul>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_4: file(relativePath: {regex: "/hero\/4.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
  }
`;

export default Piercing;
